import './Progress.css'

function Progress() {
  return (
    <div className='container-progress'>
      Work in progress!!! Check back soon...
    </div>
  )
}
export default Progress
